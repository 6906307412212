<template>
  <b-form @submit.prevent="saveSettings">
    <b-card
      title="App Settings"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            label="App Name"
            label-for="mc-app-name"
          >
            <b-form-input
              id="mc-app-name"
              v-model="details.app_name"
              placeholder="App Name"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Email"
            label-for="mc-email"
          >
            <b-form-input
              id="mc-email"
              v-model="details.email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Phone Number"
            label-for="mc-phone-number"
          >
            <b-form-input
              id="mc-phone-number"
              v-model="details.phone_number"
              placeholder="Phone Number"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Address"
            label-for="mc-address"
          >
            <b-form-textarea
              id="mc-address"
              v-model="details.address"
              placeholder="Address"
            ></b-form-textarea>
          </b-form-group>
        </b-col>

        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Social Accounts"
    >
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Facebook"
            label-for="mc-mini"
          >
            <b-form-input
              id="mc-mini"
              v-model="details.facebook"
              placeholder="https://facebook.com/"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Twitter"
            label-for="mc-midi"
          >
            <b-form-input
              id="mc-midi"
              v-model="details.twitter"
              placeholder="https://twitter.com/"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Instagram"
            label-for="mc-maxi"
          >
            <b-form-input
              id="mc-maxi"
              v-model="details.instagram"
              placeholder="https://instagram.com/"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="TikTok"
            label-for="mc-maxi"
          >
            <b-form-input
              id="mc-maxi"
              v-model="details.instagram"
              placeholder="https://tiktok.com/"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Whatsapp"
            label-for="mc-maxi"
          >
            <b-form-input
              id="mc-maxi"
              v-model="details.instagram"
              placeholder="https://wa.me/?"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Youtube"
            label-for="mc-maxi"
          >
            <b-form-input
              id="mc-maxi"
              v-model="details.youtube"
              placeholder="https://youtube.com/"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Paystack Settings"
    >
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Base URL"
            label-for="mc-base-url"
          >
            <b-form-input
              id="mc-base-url"
              v-model="details.paystack_url"
              placeholder="Base URL"
            />
          </b-form-group>
        </b-col>        
        <b-col md="4">
          <b-form-group
            label="API Key"
            label-for="mc-api-key"
          >
            <b-form-input
              id="mc-api-key"
              v-model="details.paystack_pk"
              placeholder="API KEY"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Secret Key"
            label-for="mc-secret-code"
          >
            <b-form-input
              id="mc-secret-code"
              v-model="details.paystack_sk"
              placeholder="Secret Key"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Website Settings"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="About"
            label-for="mc-about"
          >
            <b-form-textarea
              v-model="details.baxi_apikey"
              placeholder="About"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Username"
            label-for="mc-username"
          >
            <b-form-input
              id="mc-username"
              v-model="details.baxi_username"
              placeholder="Username"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Secret Code"
            label-for="mc-secret-code"
          >
            <b-form-input
              id="mc-secret-code"
              v-model="details.baxi_secret"
              placeholder="Secret Code"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Agent ID"
            label-for="mc-agent-id"
          >
            <b-form-input
              id="mc-agent-id"
              v-model="details.baxi_agent_id"
              placeholder="Agent ID"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BSpinner, BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      settings: {},
      details: {
        transfer_limit: '0.00',
        withdrawal_limit: '0.00',
        monnify_apikey: '',
        monnify_secret: '',
        monnify_contract: '',
        monnify_walletId: '',
        baxi_apikey: '',
        baxi_secret: '',
        baxi_username: '',
        baxi_agent_Id: '',
        email: '',
        phone_number: '',
        safelock_mode: 1,
        flwv_card_trans_charge: 0,
        flwv_trans_charge_mini: 0,
        flwv_trans_charge_midi: 0,
        flwv_trans_charge_miax: 0,
        transfer_charge_mini: 0,
        transfer_charge_midi: 0,
        transfer_charge_max: 0,
      },
      loading: false,
    }
  },
  created() {
    this.getSettings()
  },
  methods: {
    getSettings() {
      this.$http.get(`${this.$url}/settings`)
        .then(response => {
          if (response.status) {
            this.settings = response.data.data.app_setting
            this.details.transfer_limit = this.settings.transfer_limit
            this.details.withdrawal_limit = this.settings.withdrawal_limit

            this.details.monnify_apikey = this.settings.monnify_apikey
            this.details.monnify_secret = this.settings.monnify_secret
            this.details.monnify_contract = this.settings.monnify_contract
            this.details.monnify_walletId = this.settings.monnify_walletId

            this.details.baxi_apikey = this.settings.baxi_apikey
            this.details.baxi_secret = this.settings.baxi_secret
            this.details.baxi_username = this.settings.baxi_username
            this.details.baxi_agent_id = this.settings.baxi_agent_id
            this.details.app_name = this.settings.app_name
            this.details.referral_bonus = this.settings.referral_bonus
            this.details.safelock_interest_rate = this.settings.safelock_interest_rate
            this.details.email = this.settings.email
            this.details.phone_number = this.settings.phone_number

            this.details.safelock_mode = this.settings.safelock_mode
            this.details.safelock_mini = this.settings.safelock_mini
            this.details.safelock_midi = this.settings.safelock_midi
            this.details.safelock_maxi = this.settings.safelock_maxi

            this.details.transfer_charge_mini = this.settings.transfer_charge_mini
            this.details.transfer_charge_midi = this.settings.transfer_charge_midi
            this.details.transfer_charge_max = this.settings.transfer_charge_max

            this.details.flwv_trans_charge_mini = this.settings.flwv_trans_charge_mini
            this.details.flwv_trans_charge_midi = this.settings.flwv_trans_charge_midi
            this.details.flwv_trans_charge_max = this.settings.flwv_trans_charge_max
            this.details.flwv_card_trans_charge = this.settings.flwv_card_trans_charge
          }
        })
    },
    saveSettings() {
      this.loading = true
      this.$http.post(`${this.$url}/settings`, this.details)
        .then(response => {
          if (response.data.status) {
            window.location.reload()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
